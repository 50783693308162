jQuery(function($){
	/*
		* Replace all SVG images with inline SVG
	*/
	document.querySelectorAll('img.svg').forEach(function(img){
		var imgID = img.id;
		var imgClass = img.className;
		var imgURL = img.src;
		
		fetch(imgURL).then(function(response) {
			return response.text();
			}).then(function(text){
			
			var parser = new DOMParser();
			var xmlDoc = parser.parseFromString(text, "text/xml");
			
			// Get the SVG tag, ignore the rest
			var svg = xmlDoc.getElementsByTagName('svg')[0];
			
			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				svg.setAttribute('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				svg.setAttribute('class', imgClass+' replaced-svg');
			}
			
			// Remove any invalid XML tags as per http://validator.w3.org
			svg.removeAttribute('xmlns:a');
			
			// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
			if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
				svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
			}
			
			// Replace image with new SVG
			img.parentNode.replaceChild(svg, img);
			
		});
	});
	//
	$('#navbar-content').on('hide.bs.collapse', function () {
		$('.navbar-toggler').removeClass('open');
	})
	$('#navbar-content').on('show.bs.collapse', function () {
		$('.navbar-toggler').addClass('open');
	})
	//
	myshrink();
	
	$(".Modern-Slider").slick({
		dots:true,
		appendDots:$('.wrapSliderHome'),
		arrows:true,
		prevArrow:'<img alt="arrow" class="prevSlid" src="/wp-content/themes/theme/images/slid_left.png" />',
		nextArrow:'<img alt="arrow" class="nextSlid" src="/wp-content/themes/theme/images/slid_right.png" />',		
		autoplay: true,
		autoplaySpeed:9000,
		speed:700,
		/*mobileFirst: true,*/
		slidesToShow:1,
		slidesToScroll:1,
		pauseOnHover:false,
		/*respondTo:'min',*/
		cssEase:'linear',
		fade:true,
	});
	
	$('.galeriaSlick').slick({
		dots: false,
		arrows:false,
		infinite: true,
		slidesToShow: 10,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		pauseOnFocus: true,
		
		cssEase: 'ease-out',
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
				}
			},		
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});
	
	/**/
	
	
	
});

jQuery(window).scroll(function() {
	myshrink();
});

jQuery(window).on("load",function($){
    window.sr = ScrollReveal({ reset: false, mobile: true });
	
	sr.reveal('.myanim', { duration: 500, scale: 0.1},100);
	
	var s = skrollr.init({
		forceHeight: false
	});
	if (s.isMobile()) {
		s.destroy();
	}
	
	if(true) {
		jQuery('#menu-item-10').addClass('current-menu-item');
		jQuery('.menu-item-10').addClass('current-menu-item');		
	}
});

function myshrink() {
	if (jQuery(document).scrollTop() > 100) {
		jQuery('.oberMenu').addClass('shrink');
		} else {
		jQuery('.oberMenu').removeClass('shrink');
	}	
}